import MasonryCards from "./MasonryCards";

const WorkPortfolio = () => {
  return (
    <div className="row">
      <div>WorkPortfolio</div>
      <MasonryCards />
    </div>
  );
};

export default WorkPortfolio;
